var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"flex":"1"},attrs:{"id":"settings_accountPAgeZona_a"}},[_c('pageHeader',{staticClass:"canna_settingsPageHeader",attrs:{"showSubtitle":false,"showTitleAltZone":false,"showTlbZone":false,"cls_title_altZone":'pruebaAltZonewwww'},scopedSlots:_vm._u([(1==333)?{key:"altZone",fn:function(){return [_c('q-btn',{attrs:{"color":"app_mainBtn","label":"Guardar cambios","no-caps":"","unelevated":""},on:{"click":function($event){$event.stopPropagation();return _vm.doSaveDataAccount($event)}}})]},proxy:true}:null],null,true)},[_vm._t("default",function(){return [_c('div',{staticClass:"demozzzwww",staticStyle:{"width":"calc(100% - 10px)","position":"relative"}},[_c('div',[_vm._v("\n          Cuenta\n        ")])])]})],2),_c('div',{staticClass:"canna_settings_pageZoneContents",staticStyle:{"overflow":"auto"},attrs:{"id":"settings_accountPageZona_contents"}},[_c('div',{staticClass:"settings_accountZoneContainer_a",staticStyle:{"width":"calc(100% - 48px)"}},[_c('div',{staticClass:"canna_account_zone canna_account_zone_alt"},[_c('div',{staticClass:"canna_formZone canna_formZoneContainer",staticStyle:{"border-bottom":"1px solid rgb(241, 241, 244)"}},[_c('ValidationObserver',{ref:"formstepA",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('q-form',{ref:"myFormStepA",staticClass:"canna_forma_zona",staticStyle:{"margin-top":"0px"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onNewAccountSubmit)},"reset":_vm.onNewAccountReset}},[_c('ValidationProvider',{attrs:{"rules":"required|cannaTextNames|emojiFree|min:4|max:70","name":"nombre","immediate":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{staticClass:"canna_forma_campo_container"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"fieldLabel text-left",class:{
                          'fieldError':invalid && validated
                        }},[_vm._v("Nombre")]),_c('div',{staticClass:"requiredLabel text-right"},[_vm._v("\n                        Obligatorio\n                      ")])]),_c('q-input',{attrs:{"filled":"","name":"nombre","hint":"Este dato no puede ser editado","readonly":true,"disable":true,"dense":true,"label":(_vm.formData.nombre==='')? '':'',"error":invalid && validated,"error-message":(errors[0] !==undefined) ?  errors[0].replace('nombre','El nombre').replace('El campo','') : '',"disabled":_vm.processingData},model:{value:(_vm.formData.nombre),callback:function ($$v) {_vm.$set(_vm.formData, "nombre", $$v)},expression:"formData.nombre"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|cannaTextNames|emojiFree|min:4|max:70","name":"Paterno","immediate":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var invalid = ref.invalid;
                        var validated = ref.validated;
return [_c('div',{staticClass:"canna_forma_campo_container spacerRowTop24"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"fieldLabel text-left",class:{
                          'fieldError':invalid && validated
                        }},[_vm._v("Apellido paterno")]),_c('div',{staticClass:"requiredLabel text-right"},[_vm._v("\n                        Obligatorio\n                      ")])]),_c('q-input',{attrs:{"filled":"","name":"paterno","hint":"Este dato no puede ser editado","readonly":true,"disable":true,"dense":true,"label":(_vm.formData.paterno==='')?'':'',"error":invalid && validated,"error-message":(errors[0] !==undefined) ?  errors[0].replace('Paterno','apellido paterno').replace('El campo','El') : '',"disabled":_vm.processingData},model:{value:(_vm.formData.paterno),callback:function ($$v) {_vm.$set(_vm.formData, "paterno", $$v)},expression:"formData.paterno"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|cannaTextNames|emojiFree|min:4|max:30","name":"Materno","immediate":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var invalid = ref.invalid;
                        var validated = ref.validated;
return [_c('div',{staticClass:"canna_forma_campo_container spacerRowTop24"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"fieldLabel text-left",class:{
                          'fieldError':invalid && validated
                        }},[_vm._v("Apellido materno")]),_c('div',{staticClass:"requiredLabel text-right"},[_vm._v("\n                        Obligatorio\n                      ")])]),_c('q-input',{attrs:{"filled":"","name":"materno","hint":"Este dato no puede ser editado","readonly":true,"disable":true,"dense":true,"label":(_vm.formData.materno==='')?'':'',"error":invalid && validated,"error-message":(errors[0] !==undefined) ?  errors[0].replace('Materno','apellido materno').replace('El campo','El') : '',"disabled":_vm.processingData},model:{value:(_vm.formData.materno),callback:function ($$v) {_vm.$set(_vm.formData, "materno", $$v)},expression:"formData.materno"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|email|emojiFree|min:4|max:70","name":"Email","immediate":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var invalid = ref.invalid;
                        var validated = ref.validated;
return [_c('div',{staticClass:"canna_forma_campo_container spacerRowTop24"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"fieldLabel text-left",class:{
                          'fieldError':invalid && validated
                        }},[_vm._v("Correo electrónico")]),_c('div',{staticClass:"requiredLabel text-right"},[_vm._v("\n                        Obligatorio\n                      ")])]),_c('q-input',{attrs:{"filled":"","name":"nombre","type":"email","hint":"Este dato no puede ser editado","readonly":true,"disable":true,"dense":true,"label":(_vm.formData.email==='')?'ejemplo@email.com':'',"error":invalid && validated,"error-message":(errors[0] !==undefined) ?  errors[0].replace('Email','Correo electrónico').replace('El campo','El') : '',"disabled":_vm.processingData},on:{"input":function (val) { _vm.formData.email = ('' + val).trim()}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.email"}})],1)]}}],null,true)})],1)]}}])})],1),(333===22)?[_c('div',{staticClass:"account_photoZone"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","border-top":"0px solid #F1F1F4","padding-top":"32px"}},[_vm._m(0),_c('div',{staticStyle:{"margin-left":"16px"}},[_c('div',[_c('q-btn',{staticClass:"btnactionElement",staticStyle:{"width":"193px"},attrs:{"outline":"","unelevated":"","type":"button","color":"app_mainBtn","label":'Cambiar imagen de perfil'}})],1),_c('div',{staticClass:"spacerRowTop16 imageRefLabel"},[_vm._v("\n                JPG o PNG\n              ")])])])])]:_vm._e(),(1===22)?[_vm._m(1)]:_vm._e()],2)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatarPhoto"},[_c('div',[_vm._v("\n                RO\n              ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"clasS":"accountRemoveZone"}},[_c('div',{staticClass:"action_item removeAccountLink"},[_vm._v("\n              Eliminar mi cuenta de forma permanente\n            ")])])}]

export { render, staticRenderFns }