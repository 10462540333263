<template>
  <div id="settings_accountPAgeZona_a" style="flex: 1;">

    <pageHeader
      :showSubtitle="false"
      :showTitleAltZone="false"
      :showTlbZone="false"
      :cls_title_altZone="'pruebaAltZonewwww'"
      class="canna_settingsPageHeader"
    >
      <slot>
        <div class="demozzzwww" style="width: calc(100% - 10px);position: relative;">
          <div>
            Cuenta
          </div>
        </div>
      </slot>

      
      <template v-slot:altZone v-if="1==333">
        <q-btn color="app_mainBtn" label="Guardar cambios" no-caps unelevated @click.stop="doSaveDataAccount($event)"/>
      </template>

    </pageHeader>

    <div id="settings_accountPageZona_contents" class="canna_settings_pageZoneContents" style="overflow: auto;">

      <div class="settings_accountZoneContainer_a" style="width: calc(100% - 48px);">
        <!-- conenedor de formulario -->
        <div class="canna_account_zone canna_account_zone_alt">
          <div class="canna_formZone canna_formZoneContainer" style="border-bottom: 1px solid rgb(241, 241, 244);">

              <ValidationObserver ref="formstepA" v-slot="{ handleSubmit }">
                <q-form
                  ref="myFormStepA"
                  @submit.prevent="handleSubmit(onNewAccountSubmit)"
                  @reset="onNewAccountReset"
                  class="canna_forma_zona"
                  style="margin-top: 0px;"
                >
                  <!-- Campo de nombre -->
                  <ValidationProvider rules="required|cannaTextNames|emojiFree|min:4|max:70" name="nombre" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Nombre</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="nombre"
                        v-model="formData.nombre"
                        hint="Este dato no puede ser editado"
                        :readonly="true"
                        :disable="true"
                        :dense="true"
                        :label="(formData.nombre==='')? '':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('nombre','El nombre').replace('El campo','') : ''"
                        :disabled="processingData"
                      />
                    </div>
                  </ValidationProvider>

                  <!-- Campo de apellido paterno -->
                  <ValidationProvider rules="required|cannaTextNames|emojiFree|min:4|max:70" name="Paterno" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container spacerRowTop24">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Apellido paterno</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="paterno"
                        v-model="formData.paterno"
                        hint="Este dato no puede ser editado"
                        :readonly="true"
                        :disable="true"
                        :dense="true"
                        :label="(formData.paterno==='')?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('Paterno','apellido paterno').replace('El campo','El') : ''"
                        :disabled="processingData"
                      />
                    </div>
                  </ValidationProvider>

                  <!-- Campo de materno -->
                  <ValidationProvider rules="required|cannaTextNames|emojiFree|min:4|max:30" name="Materno" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container spacerRowTop24">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Apellido materno</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="materno"
                        v-model="formData.materno"
                        hint="Este dato no puede ser editado"
                        :readonly="true"
                        :disable="true"
                        :dense="true"
                        :label="(formData.materno==='')?'':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('Materno','apellido materno').replace('El campo','El') : ''"
                        :disabled="processingData"
                      >
                      </q-input>
                    </div>
                  </ValidationProvider>

                  <!-- Campo de email -->
                  <ValidationProvider rules="required|email|emojiFree|min:4|max:70" name="Email" :immediate="false" v-slot="{ errors, invalid, validated  }">
                    <div class="canna_forma_campo_container spacerRowTop24">
                      <div style="display: flex;justify-content: space-between;">
                        <div class="fieldLabel text-left"
                          :class="{
                            'fieldError':invalid && validated
                          }"
                        >Correo electrónico</div>
                        <div class="requiredLabel text-right" >
                          Obligatorio
                        </div>
                      </div>
                      <q-input
                        filled
                        name="nombre"
                        type="email"
                        v-model.trim="formData.email"
                        hint="Este dato no puede ser editado"
                        :readonly="true"
                        :disable="true"
                        :dense="true"
                        :label="(formData.email==='')?'ejemplo@email.com':''"
                        :error="invalid && validated"
                        :error-message="(errors[0] !==undefined) ?  errors[0].replace('Email','Correo electrónico').replace('El campo','El') : ''"
                        :disabled="processingData"
                        @input="val => { formData.email = ('' + val).trim()} "
                      />
                    </div>
                  </ValidationProvider>

                </q-form>
              </ValidationObserver>

          </div>

          <!-- zona de imagen -->
          <template v-if="333===22"> <!-- WIP en futuro -->
          <div class="account_photoZone" >
            <div style="display: flex;align-items: center;border-top: 0px solid #F1F1F4;padding-top: 32px;">
              <div class="avatarPhoto"><!-- avatar o imagen -->
                <div>
                  RO
                </div>

              </div>
              <div style="margin-left: 16px;">
                <div>
                  <q-btn outline unelevated  type="button" class="btnactionElement" color="app_mainBtn" 
                    :label="'Cambiar imagen de perfil'" style="width:193px;"
                  />
                </div>
                <div class="spacerRowTop16 imageRefLabel">
                  JPG o PNG
                </div>
              </div>
            </div>
          </div>
          </template>

          <template v-if="1===22"> <!-- WIP en futuro -->
            <div clasS="accountRemoveZone">
              <div class="action_item removeAccountLink">
                Eliminar mi cuenta de forma permanente
              </div>
            </div>
          </template>

        </div>

      </div>

    </div>

  </div>
</template>

<script>
import pageHeader from 'components/layout/headerPage'

import logOut_Mixin from './../../mixins/requestMix.js'
import myplatform_Mixin from './../../mixins/platformMix.js'
import helperBase_Mixin from './../../mixins/helperBase.js'

export default {
  name: 'settingsPage_Account',
  mixins:[
    helperBase_Mixin, 
    logOut_Mixin,
    myplatform_Mixin
  ],
  props:{

  },
  components: {
    // cxIcon: require('components/appIcons.vue').default,
    // cxBackground: require('components/appBackgrounds').default,
    pageHeader
  },  
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      formData:{
        nombre:'',
        paterno:'',
        materno:'',
        email:''
      }
    }
  },
  computed: {
    routeName () {
      return this.$route.name
    }
  },
  watch: {

  },
  methods: {

    doSaveDataAccount (event) {
       this.$cannaDebug('-- settingsAccount --  doSaveDataAccount')
    },
    onNewAccountReset () {

    },
    onNewAccountSubmit () { 

    },
  },
  // component especific
  beforeCreate () {
    this.$cannaDebug('-- settingsAccount --  beforeCreate')
  },
  created () {
    this.$cannaDebug('-- settingsAccount --  created')
  },
  beforeMount () {
    // this.$cannaDebug('-- settingsAccount --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- settingsAccount --  mounted')
    this.formData ={
        nombre:'' + this.getProfileData.nombre ,
        paterno:'' + this.getProfileData.paterno,
        materno:(this.getProfileData.sin_materno===1) ? ' -- Sin apellido materno --' : this.getProfileData.materno,
        email:'' + this.getProfileData.email
    } 
    this.$forceUpdate()
  },
  beforeUpdate () {
    // this.$cannaDebug('-- settingsAccount --  beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- settingsAccount --  updated')
  },
  activated () {
    this.$cannaDebug('-- settingsAccount --  activated')
  },
  deactivated () {
    // this.$cannaDebug('-- settingsAccount --  deactivated')
  },
  beforeDestroy () {
    this.$cannaDebug('-- settingsAccount --  beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- settingsAccount --  destroyed')
  }
}
</script>
